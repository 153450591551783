<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <department-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="columns">
                  <div class="column">
                    <h3 class="has-text-centered-mobile is-marginless">
                      Departments List
                    </h3>
                  </div>
                </div>
                <div :class="{ 'hide-edges': hideEdges }">
                  <v-client-table
                    ref="allDepartments"
                    :columns="deptTableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                  >
                    <template slot="afterLimit">
                      <download-buttons
                        @clicked="downloadFile"
                      />
                    </template>
                    <template
                      slot="department"
                      slot-scope="props"
                    >
                      <p v-if="curUserType === $ADMINISTRATOR">
                        <a @click.prevent="editDepartment(props.row)">
                          {{ props.row.name }}
                        </a>
                        <button
                          class="button is-primary reduced-height"
                          @click.prevent="showUnits(props.row)"
                        >
                          See Units
                        </button>
                      </p>
                      <p v-else>
                        {{ props.row.name }}
                      </p>
                    </template>

                    <template
                      slot="contactName"
                      slot-scope="props"
                    >
                      {{ props.row.defaultInvoiceContactInfo ? props.row.defaultInvoiceContactInfo.contactName : null }}
                    </template>

                    <template
                      slot="contactEmail"
                      slot-scope="props"
                    >
                      <p v-if="curUserType === $ADMINISTRATOR">
                        {{ props.row.defaultInvoiceContactInfo ? props.row.defaultInvoiceContactInfo.emailAddress : null }}
                      </p>
                      <p v-else>
                        <a
                          :href="`mailto:${props.row.defaultInvoiceContactInfo.emailAddress}?subject=${encodeURIComponent('Payment Information Request')}`"
                          targer="_blank"
                        >
                          {{ props.row.defaultInvoiceContactInfo.emailAddress }}
                        </a>
                      </p>
                    </template>
                    <template
                      slot="onlyAdmin"
                      slot-scope="props"
                    >
                      This is an only admin column.
                      {{ props }}
                    </template>
                  </v-client-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <edit-department-modal
          :department="department"
          :should-show-edit-department="shouldShowEditDepartment"
          @delete-button-clicked="closeEditDepartment"
        />

        <!-- :table-options="tableOptions" -->
        <show-units-modal
          :department="department"
          :is-loading="isLoading"
          :units="department.units"
          :should-show-units="shouldShowUnits"
          @start-add-unit="addUnit"
          @start-edit-unit="editUnit"
          @delete-button-clicked="shouldShowUnits = false"
        />

        <add-unit-modal
          :department="department"
          :should-show-add-unit="shouldShowAddUnit"
          @delete-button-clicked="closeAddUnit"
        />

        <edit-unit-modal
          :department="department"
          :unit="unit"
          :should-show-edit-unit="shouldShowEditUnit"
          @delete-button-clicked="closeEditUnit"
        />
      </div>
    </div>
  </div>
</template>
<script>

import DownloadButtons from "@/components/DownloadButtons";
import DepartmentTabs from '@/components/DepartmentTabs';
import AddUnitModal from '@/components/AddUnitModal';
import EditUnitModal from '@/components/EditUnitModal';
import ShowUnitsModal from '@/components/ShowUnitsModal';
import EditDepartmentModal from '@/components/EditDepartmentModal';

import { options } from '@/config/tableOptions';

export default {
  name: "DepartmentsInvoice",
  components: {
    DownloadButtons,
    DepartmentTabs,
    AddUnitModal,
    EditUnitModal,
    ShowUnitsModal,
    EditDepartmentModal,
  },
  data() {
    return {
      isLoading: false,
      department: {
        name: '',
        number: '',
        id: '',
        defaultInvoiceContactInfo: {
          contactName: '',
          emailAddress: '',
        },
        contactTitle: '',
        contactPhoneNumber: '',
      },
      shouldShowEditDepartment: false,
      shouldShowUnits: false,
      shouldShowAddUnit: false,
      shouldShowEditUnit: false,
      units: [],
      unit: {
        departmentId: '',
        id: '',
        invoiceContactInfo: {
          contactName: '',
          emailAddress: '',
        },
        invoiceEmail: '',
        isDeleted: false,
        unitName: '',
      },
      deptTableColumns: [],
      tableOptions: options({
        columnsClasses: {
          contactEmail: 'td-nowrap',
          contactPhoneNumber: 'td-nowrap',
        },
        headings: {
          contactName: "Contact",
          contactTitle: "Title",
          contactPhoneNumber: "Phone",
          contactEmail: "Email",
          actions: "",
        },
        filterable: [ 'name', 'number', 'defaultInvoiceContactInfo.contactName', 'contactTitle', 'defaultInvoiceContactInfo.emailAddress', 'contactPhoneNumber' ],
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      return this.$store.state.departments.departments || [];
    },
  },
  async fetch({ store }) {
    await store.dispatch('departments/getAllDepartments');
  },
  beforeMount() {
    this.deptTableColumns = [
      "department",
      "contactName",
      "contactEmail",
    ];
  },
  methods: {
    downloadFile(type) {
      const fileName = `departments`;

      /**
       * Prepare Object to Download
       */
      let data = this.tableValues.map(d => [
        String(d.name),
        String(d.contactName),
        String(d.contactPhoneNumber),
        String(d.contactEmail),
      ]);

      data.unshift([
        "Department",
        "Contact",
        "Phone",
        "E-mail",
      ]);

      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          `Departments List`,
          { 2: { cellWidth: 'wrap' }, 3: { cellWidth: 'wrap' }}
        );
      }

      data = null;
    },
    resetEditDepartmentForm() {
      const data = this.$options.data();
      Object.assign(this.department, data.department);
      this.department.units = this.$data.units;
    },
    showUnits(department) {
      Object.assign(this.department, department);
      this.shouldShowUnits = true;
    },
    editDepartment(department) {
      this.resetEditDepartmentForm();
      if (department.defaultInvoiceContactInfo === null) {
        department.defaultInvoiceContactInfo = {
          contactName: null,
          emailAddress: null,
        };
      }
      Object.assign(this.department, department);
      this.shouldShowEditDepartment = true;
    },
    closeEditDepartment() {
      this.shouldShowEditDepartment = false;
    },
    editUnit(department, unit) {
      this.$data.unit = unit;
      this.shouldShowEditUnit = true;
    },
    closeEditUnit() {
      this.shouldShowEditUnit = false;
    },
    addUnit() {
      this.shouldShowAddUnit = true;
    },
    closeAddUnit() {
      this.shouldShowAddUnit = false;
    },
  },
};
</script>

<style lang="scss">

  .reduced-height {
    height: 20px;
    font-size: 14px;
    float: right;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }

</style>
