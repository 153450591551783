<template>
  <div class="container">
    <modal
      ref="edit-department"
      class="is-small"
      :settings="{ title: 'Edit Department Information' }"
      @delete-button-clicked="$emit('delete-button-clicked')"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <input-form :errors="validation.errors">
          <div class="columns">
            <div class="column is-full">
              <p class="has-text-centered">
                Updating information for:
                <br>
                <strong>
                  {{ department.number }} - {{ department.name }}
                </strong>
              </p>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="department.defaultInvoiceContactInfo.contactName"
                    name="Invoice Contact Name"
                    placeholder="Invoice Contact Name"
                    rules="required"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="department.defaultInvoiceContactInfo.emailAddress"
                    name="Invoice Contact E-mail"
                    placeholder="Invoice Contact E-mail"
                    rules="required|email"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control has-text-centered">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                    @click.prevent="submitForm(validation)"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </input-form>
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>

import Modal from '@/components/common/Modal';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "EditDepartmentModal",
  components: {
    Modal,
    VeeTextbox,
    ValidationObserver,
  },
  props: {
    department: {
      type: Object,
      default: () => {},
    },
    shouldShowEditDepartment: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    shouldShowEditDepartment(nextShouldShowEditDepartment) {
      if (nextShouldShowEditDepartment === true) {
        this.$refs['edit-department'].show();
      } else {
        this.$refs['edit-department'].hide();
      }
    },
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
  },
  methods: {
    async submitForm(validation) {
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;
      if (!this.department.id) {
        this.$warning(`Oops! something went wrong, please refresh the page and try again.`);
        this.isLoading = false;
        return;
      }
      // the api requires you to submit invoice contact info in an object called
      // "invoiceContactInfo", though the department has an object called
      // "defaultInvoiceContactInfo", so this info must be copied to a new object
      // before axios is called
      let dept = this.department;
      dept.invoiceContactInfo = {};
      dept.invoiceContactInfo.contactName = dept.defaultInvoiceContactInfo.contactName;
      dept.invoiceContactInfo.emailAddress = dept.defaultInvoiceContactInfo.emailAddress;
      const result = await this.$store.dispatch('departments/update', dept);
      if (result) {
        this.$success('Department Successfully Updated!');
        this.isLoading = false;
        this.$emit('delete-button-clicked');
      }
    },
  },
};

</script>
