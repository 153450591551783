<template>
  <div class="container">
    <modal
      ref="show-units"
      class="is-large"
      :settings="{ title: 'Department Units' }"
      @delete-button-clicked="$emit('delete-button-clicked')"
    >
      <v-client-table
        ref="allUnits"
        :columns="unitTableColumns"
        :options="tableOptions"
        :data="tableValues"
      >
        <template
          slot="unit"
          slot-scope="props"
        >
          <p v-if="curUserType === $ADMINISTRATOR">
            <a @click.prevent="$emit('start-edit-unit', props.name, props.row)">
              {{ props.row.unitName }}
            </a>
          </p>
          <p v-else>
            {{ props.row.unitName }}
          </p>
        </template>

        <template
          slot="contactName"
          slot-scope="props"
        >
          {{ props.row.invoiceContactInfo.contactName }}
        </template>

        <template
          slot="contactEmail"
          slot-scope="props"
        >
          <p v-if="curUserType === $ADMINISTRATOR">
            {{ props.row.invoiceContactInfo.emailAddress }}
          </p>
          <p v-else>
            <a
              :href="`mailto:${props.row.invoiceContactInfo.emailAddress}?subject=${encodeURIComponent('Payment Information Request')}`"
              targer="_blank"
            >
              {{ props.row.invoiceContactInfo.emailAddress }}
            </a>
          </p>
        </template>
      </v-client-table>

      <div class="field">
        <div class="control has-text-centered">
          <button
            class="button is-primary"
            :class="{ 'is-loading': isLoading }"
            :disabled="isLoading"
            @click="$emit('start-add-unit')"
          >
            Add Unit
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import Modal from '@/components/common/Modal';
import { options } from '@/config/tableOptions';

export default {
  name: "ShowUnitsModal",
  components: {
    Modal,
  },
  props: {
    department: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    shouldShowUnits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unitTableColumns: [],
      tableOptions: options({
        columnsClasses: {
          contactEmail: 'td-nowrap',
          contactPhoneNumber: 'td-nowrap',
        },
        headings: {
          unit: "Unit",
          contactName: "Contact",
          contactEmail: "Email",
        },
        filterable: [ 'unitName', 'invoiceContactInfo.contactName', 'invoiceContactInfo.emailAddress' ],
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      let units = [];
      const departmentIndex = this.$store.state.departments.departments.findIndex(d => d.id === this.department.id);
      if (departmentIndex !== -1) {
        units = this.$store.state.departments.departments[departmentIndex].units || [];
      }
      return units;
    },
  },
  watch: {
    shouldShowUnits(nextShouldShowUnits) {
      if (nextShouldShowUnits === true) {
        this.$refs['show-units'].show();
      } else {
        this.$refs['show-units'].hide();
      }
    },
  },
  beforeMount() {
    this.unitTableColumns = [
      "unit",
      "contactName",
      "contactEmail",
    ];
  },
};

</script>
