<template>
  <div class="container">
    <modal
      ref="edit-unit"
      class="is-small"
      :settings="{ title: 'Edit Unit Information' }"
      @delete-button-clicked="$emit('delete-button-clicked')"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <!-- ref="edit-unit-form"
      tag="form"
      class="content"
      @submit.prevent="submitEditUnitForm" -->
        <input-form :errors="validation.errors">
          <div class="columns">
            <div class="column is-full">
              <p class="has-text-centered">
                Updating information for:
                <br>
                <strong>
                  {{ department.number }} - {{ department.name }} - {{ unit.unitName }}
                </strong>
              </p>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="unit.invoiceContactInfo.contactName"
                    name="Unit Invoice Contact Name"
                    placeholder="Unit Invoice Contact Name"
                    rules="required"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="unit.invoiceContactInfo.emailAddress"
                    name="Unit Invoice Contact E-mail"
                    placeholder="Unit Invoice Contact E-mail"
                    rules="required|email"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control has-text-centered">
                  <button
                    type="submit"
                    class="button is-primary side-margins"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                    @click.prevent="submitEditUnitForm(validation)"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    class="button is-danger side-margins"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                    @click="deleteRecord"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </input-form>
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>

import Modal from '@/components/common/Modal';
import InputForm from '@/components/InputForm';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "EditUnitModal",
  components: {
    Modal,
    VeeTextbox,
    ValidationObserver,
  },
  props: {
    department: {
      type: Object,
      default: () => {},
    },
    unit: {
      type: Object,
      default: () => [],
    },
    shouldShowEditUnit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    shouldShowEditUnit(nextShouldShowEditUnit) {
      if (nextShouldShowEditUnit === true) {
        this.$refs['edit-unit'].show();
      } else {
        this.$refs['edit-unit'].hide();
      }
    },
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
  },
  methods: {
    async deleteRecord() {
      this.isLoading = true;
      let theUnit = {
        departmentId: this.department.id,
        unitId: this.unit.id,
        unitName: this.unit.unitName,
        invoiceContactInfo: {
          contactName: this.unit.invoiceContactInfo.contactName,
          emailAddress: this.unit.invoiceContactInfo.emailAddress,
        },
        invoiceContactId: this.unit.invoiceContactId,
        isDeleted: this.unit.isDeleted,
      };
      const result = await this.$store.dispatch('units/delete', theUnit);
      if (result) {
        this.$success('Department Unit Successfully Deleted!');
        this.isLoading = false;
        this.$emit('delete-button-clicked', this.unit);
      }
    },

    async submitEditUnitForm(validation) {
      // const isValid = await this.$refs['edit-unit-form'].validate();
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;
      if (!this.department.id) {
        this.$warning(`Oops! something went wrong, please refresh the page and try again.`);
        this.isLoading = false;
        return;
      }

      let theUnit = {
        departmentId: this.department.id,
        unitId: this.unit.id,
        unitName: this.unit.unitName,
        invoiceContactInfo: {
          contactName: this.unit.invoiceContactInfo.contactName,
          emailAddress: this.unit.invoiceContactInfo.emailAddress,
        },
        isDeleted: this.unit.isDeleted,
      };

      const result = await this.$store.dispatch('units/update', theUnit);
      if (result) {
        this.$success('Department Unit Successfully Updated!');
        this.isLoading = false;
        this.$emit('delete-button-clicked', this.unit);
      }
    },
  },
};

</script>

<style scoped>

.side-margins {
  margin-left: 4px;
  margin-right: 4px;
}

</style>
