<template>
  <div class="container">
    <modal
      ref="add-unit"
      class="is-small"
      :settings="{ title: 'Add Unit' }"
      @delete-button-clicked="$emit('delete-button-clicked')"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <input-form :errors="validation.errors">
          <div class="columns">
            <div class="column is-full">
              <p class="has-text-centered">
                New unit for:
                <br>
                <strong>
                  {{ department.number }} - {{ department.name }}
                </strong>
              </p>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="unit.unitName"
                    name="Unit Name"
                    placeholder="Unit Name"
                    rules="required"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="unit.invoiceContactInfo.contactName"
                    name="Unit Invoice Contact Name"
                    placeholder="Unit Invoice Contact Name"
                    rules="required"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <vee-textbox
                    v-model="unit.invoiceContactInfo.emailAddress"
                    name="Unit Invoice Contact E-mail"
                    placeholder="Unit Invoice Contact E-mail"
                    rules="required|email"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <div class="control has-text-centered">
                  <button
                    class="button is-primary"
                    :class="{ 'is-loading': isLoading }"
                    :disabled="isLoading"
                    @click.prevent="submitAddUnitForm(validation)"
                  >
                    Add Unit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </input-form>
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>

import Modal from '@/components/common/Modal';
import InputForm from '@/components/InputForm';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "AddUnitModal",
  components: {
    Modal,
    VeeTextbox,
    ValidationObserver,
  },
  props: {
    department: {
      type: Object,
      default: () => {},
    },
    shouldShowAddUnit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      unit: {
        departmentId: '',
        invoiceContactInfo: {
          contactName: '',
          emailAddress: '',
        },
        unitName: '',
      },
    };
  },
  watch: {
    shouldShowAddUnit(nextShouldShowAddUnit) {
      if (nextShouldShowAddUnit === true) {
        this.$refs['add-unit'].show();
      } else {
        this.$refs['add-unit'].hide();
      }
    },
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
  },
  methods: {
    async submitAddUnitForm(validation) {
      // const isValid = await this.$refs['add-unit-form'].validate();
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;
      if (!this.department.id) {
        this.$warning(`Oops! something went wrong, please refresh the page and try again.`);
        this.isLoading = false;
        return;
      }
      this.unit.departmentId = this.department.id;

      const result = await this.$store.dispatch('units/add', this.unit);
      if (result) {
        this.$success('Department Unit Successfully Updated!');
        this.isLoading = false;
        this.unit = {
          departmentId: '',
          invoiceContactInfo: {
            contactName: '',
            emailAddress: '',
          },
          unitName: '',
        },
        this.$emit('delete-button-clicked');
      }
    },
  },
};

</script>
